import './css/Admin.css';
import React, {useEffect, useState} from 'react';
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Table} from 'reactstrap';
import {baseUrl} from '../shared/baseUrl';
import ReactPaginate from 'react-paginate';
import {SiMicrosoftexcel} from 'react-icons/si';
import {IoAddCircleOutline} from "react-icons/io5";
import {ImBin, ImPencil} from 'react-icons/im';
import axios from 'axios';
import * as XLSX from 'xlsx';

function parseDateTime(dateTimeString) {
    const dateTime = new Date(dateTimeString);
    const options = {
        day: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
    };
    const formattedDate = dateTime.toLocaleString('fr-FR', options);
    return formattedDate.replace('à', '•');
}

const UserComponent = () => {
    const [pageIndex, setPageIndex] = useState(0);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState(new Set());
    const [isLoading, setIsLoading] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    // Edit modal state
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [updatedUser, setUpdatedUser] = useState({
        name: '', email: '', code: '', accountType: '', address: '', password: '', accountingEmail: '', orderEmail: '', contactPerson: '', clientGroup: '',
        invoice: { address: '', postalCode: '', city: '', province: '', phone: '', phone2: '', fax: '' },
        expedition: { address: '', postalCode: '', city: '', province: '', phone: '', phone2: '', fax: '' },
    });

    const [newUser, setNewUser] = useState({
        name: '', email: '', code: '', accountType: '', address: '', password: '', accountingEmail: '', orderEmail: '', contactPerson: '', clientGroup: '',
        invoice: { address: '', postalCode: '', city: '', province: '', phone: '', phone2: '', fax: '' },
        expedition: { address: '', postalCode: '', city: '', province: '', phone: '', phone2: '', fax: '' },
    });

    const handleCheckboxChange = (userId) => {
        setSelectedUsers(prevSelected => {
            const newSet = new Set(prevSelected);
            if (newSet.has(userId)) {
                newSet.delete(userId);
            } else {
                newSet.add(userId);
            }
            return newSet;
        });
    };

    const handleSelectAll = (e) => {
        setSelectAll(e.target.checked);
        if (e.target.checked) {
            setSelectedUsers(new Set(['ALL']));
        } else {
            setSelectedUsers(new Set());
        }
    };


    const exportSelectedUsers = async () => {
        const userIds = Array.from(selectedUsers);

        try {
            const bearer = 'Bearer ' + localStorage.getItem('token');
            let response;

            // If "Select All" is checked, fetch all users
            if (userIds.includes('ALL')) {
                response = await axios.post(baseUrl + "user/user/export", {}, {
                    headers: { 'Authorization': bearer }
                });
            } else {
                response = await axios.post(baseUrl + "user/user/export", { userIds }, {
                    headers: { 'Authorization': bearer }
                });
            }

            const selectedData = response.data.map(user => ({
                ID: user._id,
                'Nom de client': user.name,
                'Courriel': user.email,
                'Numéro de compte': user.code,
                'Type de compte': user.accountType,
                'Numéro et nom de rue': user.address,
                'Courriel de comptabilité': user.accountingEmail,
                'Courriel de commande': user.orderEmail,
                'Personne à contacter': user.contactPerson,
                'Groupe de clients': user.clientGroup,
                'Facturation Numéro et nom de rue': user.invoice?.address,
                'Facturation Code postal': user.invoice?.postalCode,
                'Facturation Ville': user.invoice?.city,
                'Facturation Province': user.invoice?.province,
                'Facturation Téléphone': user.invoice?.phone,
                'Facturation Téléphone 2': user.invoice?.phone2,
                'Facturation Télécopieur': user.invoice?.fax,
                'Expédition Numéro et nom de rue': user.expedition?.address,
                'Expédition Code postal': user.expedition?.postalCode,
                'Expédition Ville': user.expedition?.city,
                'Expédition Province': user.expedition?.province,
                'Expédition Téléphone': user.expedition?.phone,
                'Expédition Téléphone 2': user.expedition?.phone2,
                'Expédition Télécopieur': user.expedition?.fax,
                Created: parseDateTime(user.created),
            }));

            const worksheet = XLSX.utils.json_to_sheet(selectedData);

            // Calculate and set column widths based on the maximum length of each column
            worksheet['!cols'] = Object.keys(selectedData[0]).map(key => ({
                wch: Math.max(
                    key.length, // Header length
                    ...selectedData.map(row => (row[key] ? row[key].toString().length : 0)) // Data lengths
                )
            }));


            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
            XLSX.writeFile(workbook, 'SelectedUsers.xlsx');
        } catch (error) {
            console.error('Error exporting users:', error);
        }
    };


    const fetchUsers = async (page = 0, keyword = '') => {
        try {
            setIsLoading(true);
            const bearer = 'Bearer ' + localStorage.getItem('token');
            const response = await axios.get(baseUrl + "user/users", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearer
                },
                params: {
                    search: keyword,
                    pageIndex: page + 1,
                    limit: 10
                }
            });

            const data = response.data.docs;
            setUsers(data);
            setTotalPageCount(response.data.totalPages);
        } catch (error) {
            console.error("Error fetching users:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteUser = async (userId) => {
        try {
            const bearer = 'Bearer ' + localStorage.getItem('token');
            await axios.delete(baseUrl + "user/user", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearer
                },
                params: { _id: userId }
            });
            alert('User deleted successfully');
            fetchUsers(pageIndex);
        } catch (error) {
            console.error("Error deleting user:", error);
        }
    };

    const handlePageClick = (pagingInfo) => {
        const newPageIndex = pagingInfo.selected;
        setPageIndex(newPageIndex);
        fetchUsers(newPageIndex);
    };

    const toggleEditModal = (user = null) => {
        setIsEditModalOpen(!isEditModalOpen);
        if (user) {
            setSelectedUser(user);
            setUpdatedUser({
                name: user.name || '',
                email: user.email || '',
                code: user.code || '',
                accountType: user.accountType || '',
                address: user.address || '',
                accountingEmail: user.accountingEmail || '',
                orderEmail: user.orderEmail || '',
                contactPerson: user.contactPerson || '',
                clientGroup: user.clientGroup || '',
                invoice: {
                    address: user.invoice?.address || '',
                    postalCode: user.invoice?.postalCode || '',
                    city: user.invoice?.city || '',
                    province: user.invoice?.province || '',
                    phone: user.invoice?.phone || '',
                    phone2: user.invoice?.phone2 || '',
                    fax: user.invoice?.fax || '',
                },
                expedition: {
                    address: user.expedition?.address || '',
                    postalCode: user.expedition?.postalCode || '',
                    city: user.expedition?.city || '',
                    province: user.expedition?.province || '',
                    phone: user.expedition?.phone || '',
                    phone2: user.expedition?.phone2 || '',
                    fax: user.expedition?.fax || '',
                },
            });
        }
    };

    const handleInputChange = (e, setState, fieldGroup = null) => {
        const { name, value } = e.target;

        setState(prev => {
            if (fieldGroup) {
                return {
                    ...prev,
                    [fieldGroup]: {
                        ...prev[fieldGroup],
                        [name]: value,
                    },
                };
            }
            return { ...prev, [name]: value };
        });
    };

    const updateUser = async (e) => {
        e.preventDefault();
        try {
            const bearer = 'Bearer ' + localStorage.getItem('token');
            await axios.put(baseUrl + "user/user", {
                _id: selectedUser._id,
                ...updatedUser
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearer
                }
            });
            alert('User updated successfully');
            toggleEditModal();
            fetchUsers(pageIndex);
        } catch (error) {
            console.error("Error updating user:", error);
        }
    };

    const createUser = async (e) => {
        e.preventDefault();
        try {
            const bearer = 'Bearer ' + localStorage.getItem('token');
            await axios.post(baseUrl + "user/user", newUser, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearer
                }
            });
            alert('User created successfully');
            toggleCreateModal();
            fetchUsers(pageIndex);
        } catch (error) {
            console.error("Error creating user:", error);
        }
    };

    const toggleCreateModal = () => {
        setIsCreateModalOpen(!isCreateModalOpen);
        setNewUser({ name: '', email: '', accountType: '', code: '' , address: '', password: ''
        , accountingEmail: '', orderEmail: '', contactPerson: '', clientGroup: ''
            , invoice: { address: '', postalCode: '', city: '', province: '', phone: '', phone2: '', fax: '' }
        , expedition: { address: '', postalCode: '', city: '', province: '', phone: '', phone2: '', fax: '' }, });
    };

    useEffect(() => {
        fetchUsers(pageIndex);
    }, []);

    return (
        <div className='adminContainer'>
            <div className={'buttons-container'}>
                <Button color="success" onClick={toggleCreateModal}> Créer Compte <IoAddCircleOutline style={{fontSize: '25px'}} /></Button>
                <Button style={{background: '#e29a10', borderColor: "#e29a10"}} onClick={exportSelectedUsers} disabled={selectedUsers.length === 0}>
                    Export compte(s) selectionnée(s) <SiMicrosoftexcel style={{fontSize: '25px'}} />
                </Button>
            </div>
            <div className='adminHeader'>Users List</div>
            <div className='orderListContainer-admin-history'>
                <div className='orders-pagination'>
                    <ReactPaginate
                        className="react-paginate"
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={totalPageCount}
                        forcePage={pageIndex}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                    />
                </div>
                <div className='orderList-admin orderList-admin-history'>
                    <Table striped responsive>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Type de compte</th>
                            <th>Address</th>
                            <th>Name</th>
                            <th>Code</th>
                            <th>Email</th>
                            <th>Created</th>
                            <th>Actions</th>
                            <th>
                                <Input type="checkbox" onChange={handleSelectAll} checked={selectAll}/>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan="8" className="text-center">Loading...</td>
                            </tr>
                        ) : users.length > 0 ? (
                            users.map((ele) => (
                                <tr key={ele._id}>
                                    <td>{ele._id}</td>
                                    <td>{ele.accountType}</td>
                                    <td>{ele.address}</td>
                                    <td>{ele.name}</td>
                                    <td>{ele.code}</td>
                                    <td>{ele.email}</td>
                                    <td>{parseDateTime(ele.created)}</td>
                                    <td>
                                        <Button
                                            onClick={() => toggleEditModal(ele)}
                                            style={{backgroundColor: 'transparent', border: 'none'}}
                                        >
                                            <ImPencil size='20' color='#694708'/>
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                if (window.confirm('Are you sure you wish to delete this item?')) {
                                                    deleteUser(ele._id);
                                                }
                                            }}
                                            style={{backgroundColor: 'transparent', border: 'none'}}
                                        >
                                            <ImBin size='20' color='#694708'/>
                                        </Button>
                                    </td>
                                    <td>
                                        <td>
                                            <Input
                                                type="checkbox"
                                                checked={selectAll || selectedUsers.has(ele._id)}
                                                onChange={() => handleCheckboxChange(ele._id)}
                                                disabled={selectAll}
                                            />
                                        </td>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center">No users found.</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </div>
            </div>

            {/* Edit Modal */}
            <Modal isOpen={isEditModalOpen} toggle={toggleEditModal} size={'xl'} fullscreen={'xl'}>
                <ModalHeader toggle={toggleEditModal}>Edit User</ModalHeader>
                <ModalBody>
                    <form onSubmit={updateUser}>
                        <div className="row">
                            <div className={'col-md-4'}>
                                <h5>Général</h5>
                                <FormGroup>
                                    <Label>Numéro de compte</Label>
                                    <Input type="text" name="code" placeholder={'Entrez le numéro de compte'}
                                           value={updatedUser.code}
                                           onChange={e => handleInputChange(e, setUpdatedUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Nom de client</Label>
                                    <Input required={true} name="name" value={updatedUser.name} placeholder={'Entrez le nom de client'}
                                           onChange={e => handleInputChange(e, setUpdatedUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Courriel</Label>
                                    <Input required={true} type={'email'} name="email" value={updatedUser.email} placeholder="Entrez le courriel"
                                           onChange={e => handleInputChange(e, setUpdatedUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Type de compte</Label>
                                    <Input type={'select'} value={updatedUser.accountType} name="accountType"
                                           value={updatedUser.accountType}
                                           onChange={e => handleInputChange(e, setUpdatedUser)}>
                                        <option value="">Type de compte</option>
                                        <option value="user">User</option>
                                        <option value="admin">Admin</option>
                                    </Input>
                                    {/*<Input name="accountType" value={updatedUser.accountType}*/}
                                    {/*       onChange={e => handleInputChange(e, setUpdatedUser)}/>*/}
                                </FormGroup>
                                <FormGroup>
                                    <Label>Mot de passe</Label>
                                    <Input autoComplete={'new-password'} type="password" name="password"
                                           value={updatedUser.password}  placeholder="Entrez un mot de passe"
                                           onChange={e => handleInputChange(e, setUpdatedUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Numéro et nom de rue</Label>
                                    <Input name="address" value={updatedUser.address} placeholder="Entrez l'adresse"
                                           onChange={e => handleInputChange(e, setUpdatedUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Courriel de comptabilité</Label>
                                    <Input type="email" name="accountingEmail"
                                           value={updatedUser.accountingEmail} placeholder="Entrez le courriel de comptabilité"
                                           onChange={e => handleInputChange(e, setUpdatedUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Courriel de commande</Label>
                                    <Input type="email" name="orderEmail"
                                           value={updatedUser.orderEmail} placeholder="Entrez le courriel de commande"
                                           onChange={e => handleInputChange(e, setUpdatedUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Personne à contacter</Label>
                                    <Input type="text" name="contactPerson"
                                           value={updatedUser.contactPerson} placeholder="Entrez le nom de la personne à contacter"
                                           onChange={e => handleInputChange(e, setUpdatedUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Groupe de clients</Label>
                                    <Input type="text" name="clientGroup"
                                           value={updatedUser.clientGroup} placeholder={'Entrez le groupe de clients'}
                                           onChange={e => handleInputChange(e, setUpdatedUser)}/>
                                </FormGroup>
                            </div>

                            <div className={'col-md-4'}>
                                <h5>Facturation</h5>
                                <FormGroup>
                                    <Label>Numéro et nom de rue</Label>
                                    <Input name="address" value={updatedUser.invoice.address} placeholder="Entrez l'adresse"
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Code postal</Label>
                                    <Input name="postalCode" value={updatedUser.invoice.postalCode} placeholder="Entrez le code postal"
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Ville</Label>
                                    <Input name="city" value={updatedUser.invoice.city} placeholder={'Entrez la ville'}
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Province</Label>
                                    <Input name="province" value={updatedUser.invoice.province} placeholder="Entrez la province"
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Téléphone</Label>
                                    <Input name="phone" value={updatedUser.invoice.phone} placeholder={'Entrez le téléphone'}
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Téléphone 2</Label>
                                    <Input name="phone2" value={updatedUser.invoice.phone2} placeholder={'Entrez le téléphone 2'}
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Télécopieur</Label>
                                    <Input name="fax" value={updatedUser.invoice.fax} placeholder={'Entrez le télécopieur'}
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'invoice')}/>
                                </FormGroup>

                            </div>
                            <div className={'col-md-4'}>
                                <h5>Facturation</h5>
                                <FormGroup>
                                    <Label>Numéro et nom de rue</Label>
                                    <Input name="address" value={updatedUser.expedition.address} placeholder="Entrez l'adresse"
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Code postal</Label>
                                    <Input name="postalCode" value={updatedUser.expedition.postalCode} placeholder="Entrez le code postal"
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Ville</Label>
                                    <Input name="city" value={updatedUser.expedition.city} placeholder="Entrez la ville"
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Province</Label>
                                    <Input name="province" value={updatedUser.expedition.province} placeholder={'Entrez la province'}
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Téléphone</Label>
                                    <Input name="phone" value={updatedUser.expedition.phone} placeholder={'Entrez le téléphone'}
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Téléphone 2</Label>
                                    <Input name="phone2" value={updatedUser.expedition.phone2} placeholder={'Entrez le téléphone 2'}
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Télécopieur</Label>
                                    <Input name="fax" value={updatedUser.expedition.fax} placeholder={'Entrez le télécopieur'}
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'expedition')}/>
                                </FormGroup>
                            </div>
                        </div>

                        <ModalFooter>
                            <Button color="primary" type={'submit'}>Sauvegarder</Button>
                        </ModalFooter>
                    </form>
                </ModalBody>
            </Modal>

            {/* Create User Modal */}
            <Modal isOpen={isCreateModalOpen} toggle={toggleCreateModal} size={'xl'} fullscreen={'xl'}>
                <ModalHeader>Créer Compte</ModalHeader>
                <ModalBody>
                    <form onSubmit={createUser}>
                        <div className="row">
                            <div className={'col-md-4'}>
                                <h5>Général</h5>
                                <FormGroup>
                                    <Label>Numéro de compte</Label>
                                    <Input required={true} name="code" value={newUser.code} placeholder={'Entrez le numéro de compte'}
                                           onChange={e => handleInputChange(e, setNewUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Nom de client</Label>
                                    <Input required={true} name="name" value={newUser.name} placeholder={'Entrez le nom de client'}
                                           onChange={e => handleInputChange(e, setNewUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Courriel</Label>
                                    <Input type={'email'} required={true} name="email" value={newUser.email}  placeholder={'Entrez le courriel'}
                                           onChange={e => handleInputChange(e, setNewUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Type de compte</Label>
                                    <Input type="select" value={newUser.accountType} name="accountType" onChange={e => handleInputChange(e, setNewUser)}>
                                        <option value="">Type de compte</option>
                                        <option value="user">User</option>
                                        <option value="admin">Admin</option>
                                    </Input>
                                    {/*<Input name="accountType" value={newUser.accountType}*/}
                                    {/*       onChange={e => handleInputChange(e, setNewUser)}/>*/}
                                </FormGroup>
                                <FormGroup>
                                    <Label>Mot de passe</Label>
                                    <Input required={true} autoComplete={'new-password'} type="password" name="password"
                                           value={newUser.password} placeholder={'Entrez un mot de passe'}
                                           onChange={e => handleInputChange(e, setNewUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Numéro et nom de rue</Label>
                                    <Input name="address" value={newUser.address} placeholder={'Entrez l\'adresse'}
                                           onChange={e => handleInputChange(e, setNewUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Courriel de comptabilité</Label>
                                    <Input type="email" name="accountingEmail"
                                           value={newUser.accountingEmail} placeholder={'Entrez le courriel de comptabilité'}
                                           onChange={e => handleInputChange(e, setNewUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Courriel de commande</Label>
                                    <Input type="email" name="orderEmail"
                                           value={newUser.orderEmail} placeholder={'Entrez le courriel de commande'}
                                           onChange={e => handleInputChange(e, setNewUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Personne à contacter</Label>
                                    <Input type="text" name="contactPerson"
                                           value={newUser.contactPerson} placeholder={'Entrez le nom de la personne à contacter'}
                                           onChange={e => handleInputChange(e, setNewUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Groupe de clients</Label>
                                    <Input type="text" name="clientGroup"
                                           value={newUser.clientGroup} placeholder={'Entrez le groupe de clients'}
                                           onChange={e => handleInputChange(e, setNewUser)}/>
                                </FormGroup>
                            </div>

                            <div className={'col-md-4'}>
                                <h5>Facturation</h5>
                                <FormGroup>
                                    <Label>Numéro et nom de rue</Label>
                                    <Input name="address" value={newUser.invoice.address} placeholder="Entrez l'adresse"
                                           onChange={e => handleInputChange(e, setNewUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Code postal</Label>
                                    <Input name="postalCode" value={newUser.invoice.postalCode} placeholder={'Entrez le code postal'}
                                           onChange={e => handleInputChange(e, setNewUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Ville</Label>
                                    <Input name="city" value={newUser.invoice.city} placeholder={'Entrez la ville'}
                                           onChange={e => handleInputChange(e, setNewUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Province</Label>
                                    <Input name="province" value={newUser.invoice.province} placeholder={'Entrez la province'}
                                           onChange={e => handleInputChange(e, setNewUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Téléphone</Label>
                                    <Input name="phone" value={newUser.invoice.phone} placeholder={'Entrez le téléphone'}
                                           onChange={e => handleInputChange(e, setNewUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Téléphone 2</Label>
                                    <Input name="phone2" value={newUser.invoice.phone2} placeholder={'Entrez le téléphone 2'}
                                           onChange={e => handleInputChange(e, setNewUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Télécopieur</Label>
                                    <Input name="fax" value={newUser.invoice.fax} placeholder={'Entrez le télécopieur'}
                                           onChange={e => handleInputChange(e, setNewUser, 'invoice')}/>
                                </FormGroup>
                            </div>

                            <div className={'col-md-4'}>
                                <h5>Expédition</h5>
                                <FormGroup>
                                    <Label>Numéro et nom de rue</Label>
                                    <Input name="address" value={newUser.expedition.address} placeholder={'Entrez l\'adresse'}
                                           onChange={e => handleInputChange(e, setNewUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Code postal</Label>
                                    <Input name="postalCode" value={newUser.expedition.postalCode} placeholder={'Entrez le code postal'}
                                           onChange={e => handleInputChange(e, setNewUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Ville</Label>
                                    <Input name="city" value={newUser.expedition.city} placeholder={'Entrez la ville'}
                                           onChange={e => handleInputChange(e, setNewUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Province</Label>
                                    <Input name="province" value={newUser.expedition.province} placeholder={'Entrez la province'}
                                           onChange={e => handleInputChange(e, setNewUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Téléphone</Label>
                                    <Input name="phone" value={newUser.expedition.phone} placeholder={'Entrez le téléphone'}
                                           onChange={e => handleInputChange(e, setNewUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Téléphone 2</Label>
                                    <Input name="phone2" value={newUser.expedition.phone2} placeholder={'Entrez le téléphone 2'}
                                           onChange={e => handleInputChange(e, setNewUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Télécopieur</Label>
                                    <Input name="fax" value={newUser.expedition.fax} placeholder={'Entrez le télécopieur'}
                                           onChange={e => handleInputChange(e, setNewUser, 'expedition')}/>
                                </FormGroup>
                            </div>
                        </div>
                        <ModalFooter><Button type={'submit'}>Ajouter</Button></ModalFooter>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default UserComponent;
