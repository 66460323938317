import React, { useEffect, useState } from 'react';
import './css/Header.css'
import {
  Nav, Navbar, NavbarBrand, NavbarToggler, Collapse, NavItem,
  Button
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";
import olabplusLogo from '../assets/logo-olab.png'

import { signupUser, loginUser, logoutUser } from '../redux/ActionCreators';


import ModalAuth from './ModalAuth';
function Header({ auth }) {
  const [isModalOpenAuth, setisModalOpenAuth] = useState(false);
  const [isNavOpen, setisNavOpen] = useState(false);
  const dispatch = useDispatch()
  const paths = (auth?.user?.accountType == 'admin') ?
    [
      { label: 'Accueil', url: '/accueil' },
      { label: 'Commande', url: '/commande' },
      { label: 'Comptes', url: '/users' },
      { label: 'Historique', url: '/historique' },
      { label: 'Admin', url: '/admin' },
    ] : ((auth?.user?.accountType == 'user') ?
      [
        { label: 'Accueil', url: '/accueil' },
        { label: 'Commande', url: '/commande' },
        { label: 'Historique', url: '/historique' },
      ] : [
        { label: 'Accueil', url: '/accueil' },
      ])
  async function handleAuth(e, signup) {
    e.preventDefault();
    if (signup) {
      dispatch(signupUser(e.target))
    } else {
      dispatch(loginUser({ code: e.target.code.value, password: e.target.password.value }));
    }
    setisModalOpenAuth(!isModalOpenAuth);
  }
  async function handleLogout() {
    dispatch(logoutUser())
    setisModalOpenAuth(!isModalOpenAuth);
  }
  return (
    <div className='headerContainer'>
      <>
        <Navbar dark={true} expand="sm">
          <div className="container header">
            <NavbarBrand className="mr-auto" href="/"><img src={olabplusLogo} style={{ height: '60px' }} className='logoheader' alt='olabplus' />
            </NavbarBrand>
            <Collapse navbar className='navbarmenu'>
              <Nav right="true" navbar>
                {paths.map((path, index) => {
                  return <NavItem key={index}>
                    <NavLink className={(window.location.pathname == path.url || window.location.pathname == '/') ? 'nav-link nav-link-header nav-link-header-active' : 'nav-link nav-link-header'} to={path.url} >
                      <span className="nav-text">{path.label}</span>
                    </NavLink>
                  </NavItem>
                })}
              </Nav>
            </Collapse>

            <div className='buttonHeaderContainer order-1 '>
              <Button className='button-header' id="header-auth-login" outline onClick={() => { setisModalOpenAuth(true) }}>
                {(auth?.isAuthenticated) ? auth?.user?.name : 'LOGIN'}
              </Button>
            </div>
          </div>
        </Navbar>

        <ModalAuth isModalOpenAuth={isModalOpenAuth} handleLogout={handleLogout} setisModalOpenAuth={setisModalOpenAuth} handleAuth={handleAuth} isLoading={auth?.isLoading} auth={auth} />
      </>
    </div >

  );
};


export default Header;
