import './css/Main.css';
import React, { useState, useEffect } from 'react';
import { Navigate, Routes, Route, useLocation, NavLink } from 'react-router-dom';
import Header from './HeaderComponent.js'
import Home from './HomeComponent.js'
import Order from './OrderComponent.js'
import OrderView from './OrderViewComponent.js'
import Admin from './AdminComponent.js'
import { useSelector } from 'react-redux'
import UserComponent from "./UserComponent";
function Main() {
  // let location = useLocation();
  // useEffect(() => { }, [location])
  const auth = useSelector((state) => state.auth)
  return (
    <div className='app'>
      <Header auth={auth} />
      <Routes>
        <Route path="/accueil" element={<Home />} />
        {["admin", "user"].includes(auth?.user?.accountType) && <Route path="/historique" element={<OrderView auth={auth} />} />}
        {["admin", "user"].includes(auth?.user?.accountType) && <Route path="/commande" element={<Order auth={auth} />} />}
          {["admin", "user"].includes(auth?.user?.accountType) && <Route path="/users" element={<UserComponent auth={auth} />} />}
        {auth?.user?.accountType === 'admin' && <Route path="/admin" element={<Admin auth={auth} />} />}
        <Route path="*" element={<Navigate to="/accueil" />} />
      </Routes>

    </div>
  )
}

export default Main;
