import * as ActionTypes from './ActionTypes';
import { baseUrl } from '../shared/baseUrl';

// AUTH
export const requestLogin = () => {
  return {
    type: ActionTypes.LOGIN_REQUEST
  }
}

export const receiveLogin = (response) => {
  return {
    type: ActionTypes.LOGIN_SUCCESS,
    token: response.token,
    user: response.user || response.creds || response.profile
  }
}

export const receiveToken = (token) => {
  return {
    type: ActionTypes.REFRESH_SUCCESS,
    token: token
  }
}

export const loginError = (message) => {
  return {
    type: ActionTypes.LOGIN_FAILURE,
    message
  }
}

export const receiveUser = (user) => {
  return {
    type: ActionTypes.RECEIVEUSER_SUCCESS,
    user: user
  }
}


export const loginUser = (creds) => (dispatch) => {
  // We dispatch requestLogin to kickoff the call to the API
  dispatch(requestLogin())
  return fetch(baseUrl + 'auth/signin', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(creds)
  })
    .then(response => {
      if (response.ok) {
        return response;
      } else {
        var error = new Error('Error ' + response.status + ': ' + response.statusText);
        error.response = response;
        throw error;
      }
    },
      error => {
        throw error;
      })
    .then(response => response.json())
    .then(response => {
      if (response.token) {
        localStorage.setItem('token', response.token);
        localStorage.setItem('creds', JSON.stringify(response.profile));
        dispatch(receiveLogin(response));
      }
      else {
        var error = new Error('Error ' + response.status);
        error.response = response;
        throw error;
      }
    })
    .catch(error => dispatch(loginError(error.message)))
};


export const requestLogout = () => {
  return {
    type: ActionTypes.LOGOUT_REQUEST
  }
}

export const receiveLogout = () => {
  return {
    type: ActionTypes.LOGOUT_SUCCESS
  }
}
export const logoutUser = () => (dispatch) => {
  dispatch(requestLogout())
  localStorage.removeItem('token');
  localStorage.removeItem('creds');
  dispatch(receiveLogout())
}

//Singup

export const signupUser = (body) => (dispatch) => {
  const bearer = 'Bearer ' + localStorage.getItem('token');
  const newUser = {
    code: body.code.value,
    name: body.name.value,
    email: body.email.value,
    password: body.password.value,
    address: body.address.value,
    accountType: body.accountType.value || '',
    accountingEmail: body.accountingEmail.value || '',
    orderEmail: body.orderEmail.value || '',
    contactPerson: body.contactPerson.value || '',
    clientGroup: body.clientGroup.value || '',
    invoice: {
      address: body.invoiceAddress.value || '',
      postalCode: body.invoicePostalCode.value || '',
      city: body.invoiceCity.value || '',
      province: body.invoiceProvince.value || '',
      phone: body.invoicePhone.value || '',
      phone2: body.invoicePhone2.value || '',
      fax: body.invoiceFax.value || '',
    },
    expedition: {
      address: body.expeditionAddress.value || '',
      postalCode: body.expeditionPostalCode.value || '',
      city: body.expeditionCity.value || '',
      province: body.expeditionProvince.value || '',
      phone: body.expeditionPhone.value || '',
      phone2: body.expeditionPhone2.value || '',
      fax: body.expeditionFax.value || '',
    },
  }
  console.log(newUser, 'newuser')
  return fetch(baseUrl + 'auth/signup', {
    method: 'POST',
    body: JSON.stringify(newUser),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': bearer
    },
    credentials: 'same-origin'
  })
    .then(response => response.json())
    .then(response => {
      if (response.token) {
        localStorage.setItem('token', response.token);
        localStorage.setItem('creds', JSON.stringify(response.profile));
        dispatch(receiveLogin(response));
      }
      else {
        var error = new Error('Error ' + response.status);
        error.response = response;
        throw error;
      }
    })
    .catch(error => {
      console.log('Post user', error);
    })
}
